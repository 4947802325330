@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

/* ToDo: Many of the declared styles here don't seem to be used */
.global-Pragraph {
  width: 100%;
  font-size: 1.875rem;
  font-weight: 300;
  margin: 0;
}

.global-TitleHead {
  width: 100%;
  font-size: 3.125rem;
  font-weight: 700;
}

.global-PragrDetails {
  font-size: 1.125rem;
  font-weight: 400;
  line-height: 1.5;
}

.col-no-padding {
  padding-left: 0;
  padding-right: 0;
}

.dropbtn {
  border: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  background-color: #f1f1f1;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  flex-direction: column;
}

.dropdown-content::before {
  content: '';
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 5px solid #f1f1f1;
  position: absolute;
  top: -0.3rem;
  right: 0.3rem;
}

.dropdown-content:hover::before {
  border-bottom: 5px solid#13c28d;
}

.dropdown:hover .dropdown-content {
  display: flex;
}

button.active::after {
  content: '';
  width: 100%;
  height: 2px;
  background-color: #0d8762;
  position: absolute;
  bottom: 0;
}

@media (max-width: 576px) {
  .withOutPadding > div {
    padding: 2.5rem 1.5rem 8rem;
  }
}

.customHeight {
  box-shadow: 0px 3px 6px #1d283117 !important;
}

@media (max-width: 1199.98px) {
  .customHeight {
    height: 250px;
  }
}

@media (max-width: 991.98px) {
  .customHeight {
    height: 200px;
  }
}

@media (max-width: 767.98px) {
  .customHeight {
    height: 12.5rem;
  }

  .no-padding-in-mobile {
    padding: 0 !important;
  }

  .uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
    max-height: calc(100vh - 1rem) !important;
  }
}

@media (max-width: 575.98px) {
  .customHeight {
    height: auto;
    text-align: unset !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.25rem 0.3125rem;
  }
}

@media (min-width: 1200px) {
  .customHeight {
    height: 230px;
  }

  .dialogInDesktop > div > div {
    min-width: 600px;
  }
}

@media (max-width: 992px) {
  .styledAppBar div {
    width: 100% !important;
    display: flex !important;
    justify-content: space-between !important;
  }
}

.styledAppBar > div > span {
  background-color: #0d8762 !important;
}

.dialogInMobile > div > div {
  margin: 0;
  border-radius: 1.4375rem;
}

.dialogInDesktop > div > div {
  border-radius: 1.4375rem;
}

.k-grid {
  font-family: 'Noto Sans HK', sans-serif !important;
  line-height: 1.5 !important;
  border-width: 0 !important;
  padding: 1.3125rem 0.625rem;
  border-radius: 6px;
}

div.k-grid-header {
  border-bottom: unset;
}

.k-grid-header-wrap {
  border-radius: 5px;
}

.k-grid a {
  font-family: 'Noto Sans HK', sans-serif !important;
  font-size: 0.75rem;
}

.k-grid-header .k-header > .k-link {
  font-family: 'Noto Sans HK', sans-serif !important;
  font-size: 0.75rem;
  align-items: center;
  display: flex;
  background: #fbfbfb;
  padding: 0.5625rem 1.5rem !important;
}

.k-grid td {
  padding: 0.875rem 1.5rem !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.k-grid th,
.k-grid td {
  font-family: 'Noto Sans HK', sans-serif !important;
  font-size: 0.75rem;
  align-items: center;
  font-weight: 500 !important;
  min-height: 1.25rem;
  background: #fff;
  padding: 0.4375rem;
  border-left: unset !important;
}

.k-grid .k-grid-header-sticky,
.k-grid .k-grid-content-sticky,
.k-grid .k-grid-footer-sticky {
  border-right-width: 0 !important;
}

.k-pager-numbers .k-link {
  font-size: 0.75rem !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

.k-pager-numbers .k-link.k-state-selected {
  color: white !important;
  background: #0d8762 !important;
}

.k-pager-wrap .k-link:hover {
  background: #0d8762 !important;
  color: white !important;
}

.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  max-height: calc(100vh - 7rem);
  overflow-y: hidden;
}

.main-menu {
  --animate-duration: 0.6s;
  --animate-delay: 0.1s;
  --animation-step: 0.2;
  --animate-repeat: 1;
}

.main-menu > a,
.main-menu .make-animation {
  opacity: 0;
  transform: translate(-0.3em, 0);
  animation: showMenu var(--animate-duration);
  animation-fill-mode: both;
}

.main-menu > a:nth-child(2) {
  animation-delay: var(--animate-delay);
}

.main-menu > a:nth-child(3) {
  animation-delay: calc(var(--animate-delay) * 2);
}

.main-menu > a:nth-child(4) {
  animation-delay: calc(var(--animate-delay) * 4);
}

.main-menu > a:nth-child(5) {
  animation-delay: calc(var(--animate-delay) * 6);
}

.main-menu > a:nth-child(6) {
  animation-delay: calc(var(--animate-delay) * 8);
}

.main-menu > a:nth-child(7) {
  animation-delay: calc(var(--animate-delay) * 10);
}

.main-menu .make-animation {
  animation-delay: calc(var(--animate-delay) * 9);
}

@keyframes showMenu {
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

body.menu-open {
  overflow: hidden;
}

.k-grid .k-master-row:hover td {
  background-color: #ededed;
}

.cypress-locations-list {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.addCaseAmountInput input {
  padding: 12.5px 14px !important;
  border: unset;
}

.k-daterangepicker .k-widget.k-dateinput .k-dateinput-wrap.k-state-focused {
  border-color: #13c28d !important;
}

/* Google Info Window */
.gm-style .gm-style-iw-c {
  border-radius: 1.563rem;
}

.gm-style .gm-style-iw-c button {
  display: none !important;
}

.gm-style .gm-style-iw-t::after {
  content: none;
}

.header--opened {
  display: flex;
}

.custom-padding-for-resources-column {
  padding: 0 0.5rem;
}

.loading-indicator {
  width: 60%;
  height: 60%;
  --loading-animation-duration: 3s;
}

.loading-indicator__mask,
.loading-indicator__dot {
  animation: loadingSpinnerAnimation var(--loading-animation-duration) infinite;
  animation-fill-mode: both;
  animation-timing-function: linear;
  transform-origin: center;
}

@keyframes loadingSpinnerAnimation {
  0% {
    transform: rotate(0deg);
  }

  12.5% {
    transform: rotate(42deg);
  }

  25% {
    transform: rotate(86deg);
  }

  37.5% {
    transform: rotate(133deg);
  }

  50% {
    transform: rotate(181deg);
  }

  62.5% {
    transform: rotate(228deg);
  }

  75% {
    transform: rotate(273deg);
  }

  87.5% {
    transform: rotate(315deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

#localize-active-lang,
#localize-widget-langs a {
  text-shadow: 0px 0px 5px black;
}

@media screen and (max-width: 800px) {
  .k-daterangepicker-wrap {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .k-daterangepicker-wrap label {
    width: 48%;
    margin: 0;
  }

  .k-daterangepicker-wrap label:first-child {
    margin: 0;
  }

  .k-daterangepicker-wrap label span input {
    padding: 1rem 0.875rem;
  }
}

.case-management-slider-wrap {
  position: absolute;
  width: 60%;
  height: 100%;
  overflow: hidden;
  right: 0;
  bottom: 0;
}

.case-management-slider {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.case-management-slider:nth-child(1) {
  animation-delay: -1s;
}

.case-management-slider:nth-child(2) {
  animation-delay: 5s;
}

.case-management-slider:nth-child(3) {
  animation-delay: 11s;
}

.case-management-slider:nth-child(4) {
  animation-delay: 17s;
}

.case-management-slider[datatype-number='1'] {
  background-image: url('../../../Shared/Icons/CategoryIcons/Assets/Office1.svg');
}

.case-management-slider[datatype-number='2'] {
  background-image: url('../../../Shared/Icons/CategoryIcons/Assets/Office2.svg');
}

.case-management-slider[datatype-number='3'] {
  background-image: url('../../../Shared/Icons/CategoryIcons/Assets/Office3.svg');
}

.case-management-slider[datatype-number='4'] {
  background-image: url('../../../Shared/Icons/CategoryIcons/Assets/Office4.svg');
}

@keyframes fade-slider {
  0% {
    opacity: 0;
  }

  4.1666666667% {
    opacity: 1;
  }

  25% {
    opacity: 1;
  }

  29.1666666667% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.select-lang-option {
  padding: 0.75rem 3rem;
  cursor: pointer;
  text-align: center;
}

.select-lang-option:hover {
  background-color: #eee;
}

input[type='text'],
input[type='number'],
input {
  font-size: 16px;
}

.custom-languageIcon {
  padding: 0 !important;
  margin-right: auto !important;
  margin-left: 1rem !important;
}

.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f8f8f8 inset !important;
  -webkit-box-shadow: 0 0 0 30px #f8f8f8 inset !important;
}

.resetCapitalize {
  text-transform: lowercase !important;
}

.Mui-focused {
  color: #0d8762 !important;
}

.Mui-focused fieldset {
  border-color: #0d8762 !important;
}

.k-button.k-bare.k-title,
.k-button.k-prev-view,
.k-button.k-next-view {
  border: unset;
}

button#closeModalBtn:focus {
  background: #ddd;
}

#kendo-date-range label {
  margin: 0;
}

/* Custom ListBox */

div:has(> .custom-list-box) {
  width: auto !important;
}

.custom-list-box ul {
  padding: 0.75rem 0;
}

.custom-list-box ul li {
  padding: 0.35rem 0.875rem 0.35rem 0.25rem;
}
/* React Dates */

/* Will Reset default Style */
.DateRangePickerInput {
  padding: unset;
  border: unset !important;
  background-color: unset !important;
  border-radius: unset !important;
  display: flex !important;
  align-items: center;
  justify-content: space-between;
}

/* Will Add Custom Style to inputs */
.DateRangePickerInput .DateInput {
  width: 48%;
}

.DateRangePickerInput .DateInput .DateInput_input {
  font-size: 0.6875rem;
  font-family: 'Noto Sans HK', sans-serif !important;
  font-weight: 500;
  padding: 0.4375rem;
  height: 100%;
  border: 1px solid rgb(133, 133, 133);
  border-radius: 4px;
  line-height: 1.25;
  text-align: start;
  box-shadow: none;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: stretch;
  vertical-align: middle;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-appearance: none;
  min-height: 2.125rem;
}

.DateRangePickerInput .DateRangePickerInput_arrow {
  display: none;
}

@media only screen and (max-width: 800px) {
  .DateRangePickerInput {
    margin-bottom: 1rem;
  }

  .DateRangePickerInput .DateInput svg {
    display: none;
  }
  .DateRangePickerInput .DateRangePicker_picker {
    display: none;
  }
}

.DateRangePickerInput .DateInput .DateInput_fang {
  top: 40px !important;
}

.DateRangePickerInput .DateRangePicker_picker {
  top: 50px !important;
  z-index: 99;
}

/* Will edit everything selected including everything between a range of dates */
.CalendarDay__selected_span {
  background: #c1ffda !important;
  color: #000 !important;
  border: 1px solid #fff !important;
}

/* Will edit selected date or the endpoints of a range of dates */
.CalendarDay__selected {
  background: #0d825d !important;
  color: #fff !important;
}

/* Will edit when hovered over. _span style also has this property */
.CalendarDay__selected:hover {
  background: #0d825d !important;
  color: white !important;
}

/* Will edit when the second date (end date) in a range of dates
is not yet selected. Edits the dates between your mouse and said date */
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: #c1ffda !important;
  border: 1px solid #fff !important;
}

.CalendarDay__hovered_span:hover {
  background: #0d825d !important;
  color: #fff !important;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right: 33px solid #0d8762 !important;
}

/* Old Date Picker */

/* Will edit selected date or the endpoints of a range of dates */
.k-calendar .k-state-selected {
  background: #0d825d !important;
  color: #fff !important;
}

.k-calendar .k-state-selected span {
  background-color: unset !important;
}

/* Will edit when hovered over. _span style also has this property */
.k-calendar .k-state-selected:hover {
  background: #0d825d !important;
  color: white !important;
}

.k-calendar .k-range-mid {
  background: #c1ffda !important;
}

.Mui-focusVisible button {
  border: 1px solid #000;
}

.input-wrong-date {
  color: #f31700 !important;
}
