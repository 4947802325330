:root {
  --gray: #a3a3a3;
  --sky-blue: #3899f0;
  --subtle-blue: rgb(236, 242, 249);
  --defualt: #11ab7c;
  --dark: #0d8762;
}

.chevron {
  color: var(--gray);
}

.chevron.open {
  transition: all 0.75s;
  transform: rotate(180deg);
}

.chevron.closed {
  transition: all 0.75s;
  transform: rotate(0deg);
}

.menu-icon {
  color: var(--gray);
  font-size: '2.7rem';
  transition: all 0.75s;
}

.menu-icon.open {
  transition: all 0.75s;
}

.menu-icon.closed {
  transition: all 0.75s;
}

/* https://davidwalsh.name/css-slide */
.sub-menu-list {
  list-style-type: none;
  overflow-y: hidden;
  transition: all 0.75s;
  margin: 0;
  padding: 0rem 1rem;
  text-align: center;
}

.sub-menu-list.open {
  max-height: 12rem; /* approximate max height */
  transition: all 0.3s;
}

.sub-menu-list.closed {
  max-height: 0;
  transition: all 0.3s;
}

.reporting-sidebar-selected {
  border-radius: 8px;
  background: #0d8762;
  color: #fff !important;
  margin: 0;
}

.reporting-sidebar-selected > div {
  fill: #fff;
}

.on-main-section {
  color: var(--dark);
}
